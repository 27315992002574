import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { TextField, ITextFieldProps, Checkbox, ICheckboxProps, Dropdown, IDropdownProps, IDropdownOption } from "@fluentui/react";
import { HelpCallout } from "../HelpCallout";
import { GPT4VSettings } from "../GPT4VSettings";
import { VectorSettings } from "../VectorSettings";
import { RetrievalMode, VectorFieldOptions, GPT4VInput } from "../../api";
import styles from "./Settings.module.css";

// Add type for onRenderLabel
type RenderLabelType = ITextFieldProps | IDropdownProps | ICheckboxProps;

export interface SettingsProps {
    promptTemplate: string;
    temperature: number;
    retrieveCount: number;
    seed: number | null;
    minimumSearchScore: number;
    minimumRerankerScore: number;
    useSemanticRanker: boolean;
    useSemanticCaptions: boolean;
    excludeCategory: string;
    includeCategory: string;
    retrievalMode: RetrievalMode;
    useGPT4V: boolean;
    gpt4vInput: GPT4VInput;
    vectorFieldList: VectorFieldOptions[];
    showSemanticRankerOption: boolean;
    showGPT4VOptions: boolean;
    showVectorOption: boolean;
    useOidSecurityFilter: boolean;
    useGroupsSecurityFilter: boolean;
    useLogin: boolean;
    loggedIn: boolean;
    requireAccessControl: boolean;
    className?: string;
    onChange: (field: string, value: any) => void;
    shouldStream?: boolean; // Only used in Chat
    useSuggestFollowupQuestions?: boolean; // Only used in Chat
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    showSuggestFollowupQuestions?: boolean;
}

export const Settings = ({
    promptTemplate,
    temperature,
    retrieveCount,
    seed,
    minimumSearchScore,
    minimumRerankerScore,
    useSemanticRanker,
    useSemanticCaptions,
    excludeCategory,
    includeCategory,
    retrievalMode,
    useGPT4V,
    gpt4vInput,
    vectorFieldList,
    showSemanticRankerOption,
    showGPT4VOptions,
    showVectorOption,
    useOidSecurityFilter,
    useGroupsSecurityFilter,
    useLogin,
    loggedIn,
    requireAccessControl,
    className,
    onChange,
    shouldStream,
    useSuggestFollowupQuestions,
    promptTemplatePrefix,
    promptTemplateSuffix,
    showSuggestFollowupQuestions
}: SettingsProps) => {
    const { t } = useTranslation();

    // Form field IDs
    const promptTemplateId = useId("promptTemplate");
    const promptTemplateFieldId = useId("promptTemplateField");
    const temperatureId = useId("temperature");
    const temperatureFieldId = useId("temperatureField");
    const seedId = useId("seed");
    const seedFieldId = useId("seedField");
    const searchScoreId = useId("searchScore");
    const searchScoreFieldId = useId("searchScoreField");
    const rerankerScoreId = useId("rerankerScore");
    const rerankerScoreFieldId = useId("rerankerScoreField");
    const retrieveCountId = useId("retrieveCount");
    const retrieveCountFieldId = useId("retrieveCountField");
    const includeCategoryId = useId("includeCategory");
    const includeCategoryFieldId = useId("includeCategoryField");
    const excludeCategoryId = useId("excludeCategory");
    const excludeCategoryFieldId = useId("excludeCategoryField");
    const semanticRankerId = useId("semanticRanker");
    const semanticRankerFieldId = useId("semanticRankerField");
    const semanticCaptionsId = useId("semanticCaptions");
    const semanticCaptionsFieldId = useId("semanticCaptionsField");
    const useOidSecurityFilterId = useId("useOidSecurityFilter");
    const useOidSecurityFilterFieldId = useId("useOidSecurityFilterField");
    const useGroupsSecurityFilterId = useId("useGroupsSecurityFilter");
    const useGroupsSecurityFilterFieldId = useId("useGroupsSecurityFilterField");
    const shouldStreamId = useId("shouldStream");
    const shouldStreamFieldId = useId("shouldStreamField");
    const suggestFollowupQuestionsId = useId("suggestFollowupQuestions");
    const suggestFollowupQuestionsFieldId = useId("suggestFollowupQuestionsField");

    const renderLabel = (props: RenderLabelType | undefined, labelId: string, fieldId: string, helpText: string) => (
        <HelpCallout labelId={labelId} fieldId={fieldId} helpText={helpText} label={props?.label} />
    );

    return (
        <div className={className}>
            <p>
                        Vi behandler de oplysninger, du giver os, i overensstemmelse med gældende regler. Vi er bevidste om, at dine oplysninger skal behandles
                        med respekt for oplysningernes fortrolighed og for dit privatliv.
                    </p>
                    <b>Hvem er dataansvarlig?</b>
                    <p>
                        Erhvervsministeriets departement er dataansvarlige, imens Koncern HR er ansvarlig for den daglige drift og vedligehold af HR-chatbotten.
                    </p>
                    <b>Hvad er personoplysninger?</b>
                    <p>
                        Personoplysninger er enhver form for information om en identificeret eller identificerbar fysisk person. Det vil sige alle former for
                        oplysninger, der direkte eller indirekte kan knyttes til en person. Det kan fx være for- og efternavn, en privatadresse, e-mail,
                        CPR-nummer eller oplysninger om private forhold og livsbegivenheder.
                    </p>
                    <b>Hvorfra stammer oplysningerne?</b>
                    <p>
                        Erhvervsministeriet behandler de personoplysninger, som du selv vælger at oplyse i dine spørgsmål til HR-chatbotten. Det er ikke
                        nødvendigt, og der er ingen krav om, at personoplysninger skal indgå i dine spørgsmål. Det anbefales derfor at undlade at inkludere
                        personoplysninger.
                    </p>
                    <b>Hvornår må vi bruge dine oplysninger?</b>
                    <p>
                        Erhvervsministeriet behandler dine selvoplyste personoplysninger som led i at kunne besvare dine spørgsmål til HR-chatbotten, jf.
                        databeskyttelsesforordningens artikel 6, stk. 1, litra e, behandling henhører under offentlig myndighedsudøvelse.
                    </p>
                    <b>Hvor behandles dine personoplysninger? </b>
                    <p>
                        Dine spørgsmål inkl. evt. selvoplyste personoplysninger behandles i et af Microsofts europæiske datacentre i den meget begrænsede
                        periode (sekunder), det tager HR-chatbotten at generere et svar på dit spørgsmål. Ud over dette findes din samtale, dvs. spørgsmål og
                        svar, kun i din browser. Ved hvert nyt spørgsmål i samme samtale, så sendes spørgsmål og en opsummering af evt. foregående spørgsmål og
                        svar i samme samtale til HR-chatbotten. De evt. foregående spørgsmål og svar giver HR-chatbotten kontekst, som den kan forholde sig til
                        ift. besvarelsen af det nye spørgsmål.
                    </p>
                    <b>Hvem har adgang til dine oplysninger?</b>
                    <p>Idet din samtale med HR-chatbotten ikke lagres i et datacenter, har ingen andre end dig adgang til samtalen med HR-chatbotten.</p>
                    <b>Hvordan og hvor længe opbevarer vi oplysninger om dig?</b>
                    <p>
                        Dine spørgsmål inkl. selvoplyste personoplysninger behandles i et af Microsofts europæiske datacentre i den korte periode (sekunder),
                        det tager HR-chatbotten at generere et svar på dit spørgsmål. Ud over dette findes din samtale, dvs. spørgsmål og svar, kun i din
                        browser. Når du forlader hjemmesiden med HR-chatbotten eller lukker browseren, så eksisterer samtalen ikke længere.
                    </p>
                    <b>I hvilke tilfælde videregiver vi oplysninger om dig?</b>
                    <p>
                        Der sker ingen videregivelse af oplysninger, og det kan ikke ske, idet din samtale med HR-chatbotten ikke lagres. Hvis du vælger at
                        kontakte Erhvervsministeriets Koncern HR om den samtale, du har haft med HR-chatbotten, fx ift. rigtighed eller kvalitet af svar, og
                        medsender udsnit af samtalen, så kan Koncern HR videregive udsnittet af samtalen til udviklerne af HR-chatbotten. I så fald indhentes
                        der samtykke fra dig inden videregivelsen, hvis samtalen indeholder personfølsomme oplysninger.
                    </p>
                    <b>Hvilke rettigheder har du?</b>
                    <p>
                        Databeskyttelsesforordningen giver dig en række rettigheder. Rettighederne fremgår særligt af artikel 13-18 og artikel 20-22. I det
                        følgende beskrives dine rettigheder mere overordnet. Hvis du ønsker en mere udførlig beskrivelse af dine rettigheder, kan du læse
                        forordningen, Vejledning om de registreredes rettigheder fra Datatilsynet eller rette henvendelse til vores databeskyttelsesrådgiver på
                        dpo@em.dk.
                    </p>
                    <b>Underretning om, at dine personoplysninger behandles</b>
                    <p>
                        Erhvervsministeriet skal give dig en række informationer, hvis vi behandler oplysninger om dig. Det gælder både, når vi modtager
                        oplysninger fra dig, men også når vi modtager oplysninger fra andre end dig selv. Du skal have oplysninger om:
                        <ul>
                            <li>Den dataansvarlige.</li>
                            <li>Kontaktoplysninger om databeskyttelsesrådgiveren.</li>
                            <li>Formålene med og retsgrundlaget for behandling af oplysningerne.</li>
                            <li>Hvilke oplysninger, der indsamles.</li>
                            <li>Hvor oplysningerne stammer fra.</li>
                            <li>Hvem der modtager oplysningerne.</li>
                            <li>Hvor længe oplysningerne opbevares, eller kriterier der bruges til at fastlægge, hvor længe oplysningerne opbevares.</li>
                            <li>
                                Din ret til at anmode om berigtigelse eller sletning af personoplysninger eller begrænsning af brugen af oplysninger om dig.
                            </li>
                            <li>Din ret til at klage over behandlingen af personoplysninger.</li>
                        </ul>
                        Erhvervsministeriet kan undlade at give oplysningerne, hvis det er umuligt eller vil kræve en uforholdsmæssig stor indsats, eller i de
                        tilfælde, hvor det vurderes, at du allerede må antages at være bekendt med oplysningerne.
                    </p>
                    <b>Indsigt i hvilke oplysninger, der behandles</b>
                    <p>
                        Du kan anmode om indsigt i de oplysninger, som Erhvervsministeriet behandler om dig. Det betyder, at du har ret til at se de
                        personoplysninger, vi har om dig, og at du har ret til at modtage nedenstående oplysninger om, hvordan og hvorfor vi behandler
                        oplysninger om dig:
                        <ul>
                            <li>Formålene med og retsgrundlaget for behandlingen.</li>
                            <li>Kategorier af personoplysninger, og hvis muligt, oplysninger om hvorfra de stammer.</li>
                            <li>Modtagerne af oplysningerne.</li>
                            <li>
                                Hvor længe oplysningerne vil blive opbevaret, eller kriterier, der anvendes til at fastlægge, hvor længe oplysningerne
                                opbevares.
                            </li>
                            <li>
                                Din ret til at anmode Erhvervsministeriet om berigtigelse eller sletning af personoplysninger eller begrænsning af brugen af
                                oplysninger om dig.
                            </li>
                        </ul>
                        Erhvervsministeriet kan afvise at imødekomme din anmodning om indsigt, hvis du derved vil få oplysninger, der kan krænke andres
                        rettigheder og friheder.
                    </p>
                    <b>Retten til berigtigelse af oplysninger, der behandles</b>
                    <p>
                        Du har ret til at få rettet forkerte oplysninger om dig så hurtigt som muligt, og du har ret til at få tilføjet supplerende oplysninger,
                        hvis oplysningerne er ufuldstændige.
                    </p>
                    <b>Retten til sletning af oplysninger, der behandles</b>
                    <p>
                        Du har ret til at få slettet oplysninger om dig så hurtigt som muligt. Der er dog som hovedregel ikke en mulighed for at slette
                        oplysninger på grund af forvaltningsretten, offentlighedsloven og arkivloven, som gælder for offentlige myndigheder. Du kan ikke få
                        oplysningerne slettet, så længe de er nødvendige for at opfylde formålene med Erhvervsministeriets behandling af dem, eller hvis
                        sletning vil stride mod Erhvervsministeriets forpligtelser efter gældende lovgivning.
                    </p>
                    <b>Retten til begrænsning af oplysninger, der behandles</b>
                    <p>
                        Under visse omstændigheder har du ret til, at behandlingen af oplysninger om dig begrænses, f.eks. mens det undersøges, om oplysningerne
                        er korrekte, eller om oplysningerne er nødvendige for at fastlægge et retskrav.
                    </p>
                    <b>Rettighedernes praktiske begrænsning ift. den tid data reelt lagres</b>
                    <p>
                        Da dine spørgsmål inkl. selvoplyste personoplysninger behandles i et af Microsofts europæiske datacentre i den korte periode (sekunder),
                        det tager HR-chatbotten at generere et svar på dit spørgsmål, vil det således også kun være i den tid, hvor chatbotten er i brug og
                        fortsat genererer svar til dig, at rettighederne nævnt ovenfor vil kunne imødekommes i praksis. Det skyldes, at så snart du lukker
                        chatbotten ned, ophører data med at blive lagret – og der er herefter derfor ikke mulighed for f.eks. at give indsigt i, berigtige,
                        begrænse eller slette i disse data, da de ikke længere er lagret på din computer eller serverne hvor HR-chatbotten kører.
                    </p>
                    <b>Informationssikkerhed</b>
                    <p>
                        Det er en høj prioritet at beskytte Erhvervsministeriet og borgerne mod uautoriseret adgang, ændring, offentliggørelse eller destruktion
                        af de oplysninger, vi behandler. Vi er særligt opmærksomme på at sikre dine oplysninger. I Erhvervsministeriet skal der således være et
                        tilstrækkeligt informationssikkerhedsniveau for alle ansatte og alle samarbejdspartnere samt for anvendelsen af it-ressourcer, såsom
                        it-systemer, hardware og elektroniske datamedier. Erhvervsministeriet har implementeret ISO 27001 standarden, som er en international
                        standard til etablering af et ledelsessystem for informationssikkerhed, som sikrer etablering af et ensartet, afstemt og passende
                        informationssikkerhedsniveau.
                    </p>
                    <b>Klagemuligheder</b>
                    <p>
                        Du kan klage til Datatilsynet over Erhvervsministeriets behandling af dine oplysninger, hvis du mener, at behandlingen er i strid med
                        databeskyttelsesforordningen. Datatilsynet kan kontaktes på e-mail: dt@datatilsynet.dk. Datatilsynets adresse er: Carl Jacobsens Vej 35,
                        2500 Valby. Datatilsynet er den uafhængige statslige myndighed, som fører tilsyn med, at reglerne i databeskyttelsesforordningen og
                        databeskyttelsesloven overholdes. Datatilsynet har bl.a. til opgave at vejlede og rådgive myndighederne i behandlingen af
                        personoplysninger. Datatilsynet behandler også klager over myndigheders behandling af personoplysninger samt gennemfører inspektioner
                        hos myndigheder og private virksomheder.
                    </p>
                    <b>Databeskyttelsesrådgiver</b>
                    <p>
                        Erhvervsministeriet har udpeget Ronnie Sunil Sodhi som databeskyttelsesrådgiver på vegne af Erhvervsministeriet.
                        Databeskyttelsesrådgiverens opgave er bl.a. at underrette og rådgive Erhvervsministeriet og de ansatte, der behandler personoplysninger
                        om deres forpligtelser i databeskyttelsesforordningen og databeskyttelsesloven. Dertil er det databeskyttelsesrådgivernes opgave at
                        overvåge Erhvervsministeriets syv styrelser og departements overholdelse af databeskyttelsesforordningen og sikre, at de overholder
                        reglerne om, hvordan personoplysninger behandles lovligt og sikkert. Databeskyttelsesrådgiveren kan oplyse dig nærmere om reglerne for
                        databeskyttelse. Databeskyttelsesrådgiveren kan også vejlede dig om dine rettigheder i forhold til behandling af personoplysninger i
                        Erhvervsministeriet. Databeskyttelsesrådgiveren kan kontaktes på e-mailadressen: dpo@em.dk eller på telefonnummer 33 92 33 50. Du skal
                        være opmærksom på, at ønsker du at sende sikker digital post, skal det sendes til Erhvervsministeriets hovedpostkasse (em@em.dk), som
                        videresender det til dpo@em.dk
                    </p>
                    <p>Privatlivspolitikken bliver løbende ændret, når det er nødvendigt.</p>
        </div>
        // <div className={className}>
        //     <TextField
        //         id={promptTemplateFieldId}
        //         className={styles.settingsSeparator}
        //         defaultValue={promptTemplate}
        //         label={t("labels.promptTemplate")}
        //         multiline
        //         autoAdjustHeight
        //         onChange={(_ev, val) => onChange("promptTemplate", val || "")}
        //         aria-labelledby={promptTemplateId}
        //         onRenderLabel={props => renderLabel(props, promptTemplateId, promptTemplateFieldId, t("helpTexts.promptTemplate"))}
        //     />

        //     <TextField
        //         id={temperatureFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.temperature")}
        //         type="number"
        //         min={0}
        //         max={1}
        //         step={0.1}
        //         defaultValue={temperature.toString()}
        //         onChange={(_ev, val) => onChange("temperature", parseFloat(val || "0"))}
        //         aria-labelledby={temperatureId}
        //         onRenderLabel={props => renderLabel(props, temperatureId, temperatureFieldId, t("helpTexts.temperature"))}
        //     />

        //     <TextField
        //         id={seedFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.seed")}
        //         type="text"
        //         defaultValue={seed?.toString() || ""}
        //         onChange={(_ev, val) => onChange("seed", val ? parseInt(val) : null)}
        //         aria-labelledby={seedId}
        //         onRenderLabel={props => renderLabel(props, seedId, seedFieldId, t("helpTexts.seed"))}
        //     />

        //     <TextField
        //         id={searchScoreFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.minimumSearchScore")}
        //         type="number"
        //         min={0}
        //         step={0.01}
        //         defaultValue={minimumSearchScore.toString()}
        //         onChange={(_ev, val) => onChange("minimumSearchScore", parseFloat(val || "0"))}
        //         aria-labelledby={searchScoreId}
        //         onRenderLabel={props => renderLabel(props, searchScoreId, searchScoreFieldId, t("helpTexts.searchScore"))}
        //     />

        //     {showSemanticRankerOption && (
        //         <TextField
        //             id={rerankerScoreFieldId}
        //             className={styles.settingsSeparator}
        //             label={t("labels.minimumRerankerScore")}
        //             type="number"
        //             min={1}
        //             max={4}
        //             step={0.1}
        //             defaultValue={minimumRerankerScore.toString()}
        //             onChange={(_ev, val) => onChange("minimumRerankerScore", parseFloat(val || "0"))}
        //             aria-labelledby={rerankerScoreId}
        //             onRenderLabel={props => renderLabel(props, rerankerScoreId, rerankerScoreFieldId, t("helpTexts.rerankerScore"))}
        //         />
        //     )}

        //     <TextField
        //         id={retrieveCountFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.retrieveCount")}
        //         type="number"
        //         min={1}
        //         max={50}
        //         defaultValue={retrieveCount.toString()}
        //         onChange={(_ev, val) => onChange("retrieveCount", parseInt(val || "3"))}
        //         aria-labelledby={retrieveCountId}
        //         onRenderLabel={props => renderLabel(props, retrieveCountId, retrieveCountFieldId, t("helpTexts.retrieveNumber"))}
        //     />

        //     <Dropdown
        //         id={includeCategoryFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.includeCategory")}
        //         selectedKey={includeCategory}
        //         onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IDropdownOption) => onChange("includeCategory", option?.key || "")}
        //         aria-labelledby={includeCategoryId}
        //         options={[
        //             { key: "", text: t("labels.includeCategoryOptions.all") }
        //             // { key: "example", text: "Example Category" } // Add more categories as needed
        //         ]}
        //         onRenderLabel={props => renderLabel(props, includeCategoryId, includeCategoryFieldId, t("helpTexts.includeCategory"))}
        //     />

        //     <TextField
        //         id={excludeCategoryFieldId}
        //         className={styles.settingsSeparator}
        //         label={t("labels.excludeCategory")}
        //         defaultValue={excludeCategory}
        //         onChange={(_ev, val) => onChange("excludeCategory", val || "")}
        //         aria-labelledby={excludeCategoryId}
        //         onRenderLabel={props => renderLabel(props, excludeCategoryId, excludeCategoryFieldId, t("helpTexts.excludeCategory"))}
        //     />

        //     {showSemanticRankerOption && (
        //         <>
        //             <Checkbox
        //                 id={semanticRankerFieldId}
        //                 className={styles.settingsSeparator}
        //                 checked={useSemanticRanker}
        //                 label={t("labels.useSemanticRanker")}
        //                 onChange={(_ev, checked) => onChange("useSemanticRanker", !!checked)}
        //                 aria-labelledby={semanticRankerId}
        //                 onRenderLabel={props => renderLabel(props, semanticRankerId, semanticRankerFieldId, t("helpTexts.useSemanticReranker"))}
        //             />

        //             <Checkbox
        //                 id={semanticCaptionsFieldId}
        //                 className={styles.settingsSeparator}
        //                 checked={useSemanticCaptions}
        //                 label={t("labels.useSemanticCaptions")}
        //                 onChange={(_ev, checked) => onChange("useSemanticCaptions", !!checked)}
        //                 disabled={!useSemanticRanker}
        //                 aria-labelledby={semanticCaptionsId}
        //                 onRenderLabel={props => renderLabel(props, semanticCaptionsId, semanticCaptionsFieldId, t("helpTexts.useSemanticCaptions"))}
        //             />
        //         </>
        //     )}

        //     {useLogin && (
        //         <>
        //             <Checkbox
        //                 id={useOidSecurityFilterFieldId}
        //                 className={styles.settingsSeparator}
        //                 checked={useOidSecurityFilter || requireAccessControl}
        //                 label={t("labels.useOidSecurityFilter")}
        //                 disabled={!loggedIn || requireAccessControl}
        //                 onChange={(_ev, checked) => onChange("useOidSecurityFilter", !!checked)}
        //                 aria-labelledby={useOidSecurityFilterId}
        //                 onRenderLabel={props => renderLabel(props, useOidSecurityFilterId, useOidSecurityFilterFieldId, t("helpTexts.useOidSecurityFilter"))}
        //             />
        //             <Checkbox
        //                 id={useGroupsSecurityFilterFieldId}
        //                 className={styles.settingsSeparator}
        //                 checked={useGroupsSecurityFilter || requireAccessControl}
        //                 label={t("labels.useGroupsSecurityFilter")}
        //                 disabled={!loggedIn || requireAccessControl}
        //                 onChange={(_ev, checked) => onChange("useGroupsSecurityFilter", !!checked)}
        //                 aria-labelledby={useGroupsSecurityFilterId}
        //                 onRenderLabel={props =>
        //                     renderLabel(props, useGroupsSecurityFilterId, useGroupsSecurityFilterFieldId, t("helpTexts.useGroupsSecurityFilter"))
        //                 }
        //             />
        //         </>
        //     )}

        //     {showGPT4VOptions && (
        //         <GPT4VSettings
        //             gpt4vInputs={gpt4vInput}
        //             isUseGPT4V={useGPT4V}
        //             updateUseGPT4V={val => onChange("useGPT4V", val)}
        //             updateGPT4VInputs={val => onChange("gpt4vInput", val)}
        //         />
        //     )}

        //     {showVectorOption && (
        //         <VectorSettings
        //             defaultRetrievalMode={retrievalMode}
        //             showImageOptions={useGPT4V && showGPT4VOptions}
        //             updateVectorFields={val => onChange("vectorFieldList", val)}
        //             updateRetrievalMode={val => onChange("retrievalMode", val)}
        //         />
        //     )}

        //     {/* Streaming checkbox for Chat */}
        //     {shouldStream !== undefined && (
        //         <Checkbox
        //             id={shouldStreamFieldId}
        //             className={styles.settingsSeparator}
        //             checked={shouldStream}
        //             label={t("labels.shouldStream")}
        //             onChange={(_ev, checked) => onChange("shouldStream", !!checked)}
        //             aria-labelledby={shouldStreamId}
        //             onRenderLabel={props => renderLabel(props, shouldStreamId, shouldStreamFieldId, t("helpTexts.streamChat"))}
        //         />
        //     )}

        //     {/* Followup questions checkbox for Chat */}
        //     {showSuggestFollowupQuestions && (
        //         <Checkbox
        //             id={suggestFollowupQuestionsFieldId}
        //             className={styles.settingsSeparator}
        //             checked={useSuggestFollowupQuestions}
        //             label={t("labels.useSuggestFollowupQuestions")}
        //             onChange={(_ev, checked) => onChange("useSuggestFollowupQuestions", !!checked)}
        //             aria-labelledby={suggestFollowupQuestionsId}
        //             onRenderLabel={props =>
        //                 renderLabel(props, suggestFollowupQuestionsId, suggestFollowupQuestionsFieldId, t("helpTexts.suggestFollowupQuestions"))
        //             }
        //         />
        //     )}
        // </div>
    );
};
